<template>
  <section>
    <h1>{{ $t('editor_title') }}</h1>
    <div class="preview-display">
      <div>
        <img :src="`${currentHost}${this.$store.state.imagePath}`" alt="" id="image">
        <!--        <span ref="headline">
                  {{ `${this.$store.state.message}` }}
                </span>-->
      </div>
    </div>
    <div class=" message-controls">
      <div class="controls font-color">
        <h4>{{ $t('editor_crop_title') }}</h4>
        <!--        <div class="controls-container">
                  <ul class="text-color-wrap">
                          <li ref="colorOption" v-for="(item, i) in optionsTextColors" :key="i"
                              :style="`background: ${item.color};`"
                              @click="updateOptions(i, 'color')">
                          </li>
                  </ul>
                </div>-->
      </div>
    </div>
    <MButton class="secondary"
             :label="$t('btn_replaceImage')" @click.native.once="replaceImage()"></MButton>
    <MButton :label="$t('btn_next')" @click.native.once="saveCanvas()"></MButton>
  </section>
</template>

<script>
import MButton from '@/components/MButton.vue';
import axios from 'axios';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'ImageCropper',
  components: {
    MButton,
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      cropper: null,
      optionsTextColors: [
        {
          id: 'pink',
          color: '#E20659',
        },
        {
          id: 'lightgreen',
          color: '#BBDAB1',
        },
        {
          id: 'orange',
          color: '#EA630D',
        },
        {
          id: 'darkgreen',
          color: '#00A23C',
        },
        {
          id: 'blue',
          color: '#2D489C',
        },
        {
          id: 'yellow',
          color: '#FFE60B',
        },
        {
          id: 'magenta',
          color: '#E20659',
        },
      ],
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
    currentHost() {
      return process.env.VUE_APP_API_URL;
    },
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  mounted() {
    this.$store.state.showBackButton = true;
    this.initialEditorMode();
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    initialEditorMode() {
      const image = document.getElementById('image');
      // eslint-disable-next-line no-unused-vars
      this.cropper = new Cropper(image, {
        dragMode: 'move',
        viewMode: 0,
        aspectRatio: 312 / 208,
        restore: false,
        guides: false,
        center: false,
        minCropBoxWidth: 312,
        minCropBoxHeight: 208,
        zoom: 0.1,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
      });
    },
    updateOptions(i, type) {
      const msgPreview = document.querySelector('.preview-display span');

      switch (type) {
        case 'color':
          this.colorID = i;
          if (this.colorShadowID !== this.colorID) {
            this.$refs.colorOption.forEach((el) => {
              el.classList.remove('active');
            });

            this.messageFormat.color = i;
            this.$refs.colorOption[i].classList.add('active');
            msgPreview.style.color = this.optionsTextColors[i].color;
          }
          break;
        default:
          this.messageFormat.font = i;
          this.$refs.fontOption[i].classList.add('active');
      }

      this.$store.state.messageFormat = this.messageFormat;
    },
    replaceImage() {
      this.$router.push({ path: '/' });
    },
    saveCanvas() {
      const canvas = this.cropper.getCroppedCanvas({
        width: 312,
        height: 208,
      });

      console.log(canvas);

      canvas.toBlob((blob) => {
        const file = new File([blob], 'test.png', {
          type: 'image/png',
          lastModified: Date.now(),
        });
        const formData = new FormData();
        formData.append('file', file);

        axios.post(`${process.env.VUE_APP_API_URL}/purify/uploadImage`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            console.log('response from Cropper');
            console.log(response.data.path);
            this.pathToImage = response.data.path;
            this.$store.state.imagePath = this.pathToImage;
            // this.submitEntry();
            this.$router.push({ name: 'image-editor' });
          });
      });
    },
    /* submitEntry() {
      const formData = new FormData();

      formData.append('type', 'image');
      formData.append('message', this.$store.state.imagePath);
      formData.append('broadcasted', 0);
      formData.append('entryTime', Date.now());
      formData.append('format', null);
      formData.append('uuid', document.body.getAttribute('clientid')
        .toString());

      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}/messages/image`,
        headers: { 'Content-Type': 'application/json' },
        data: formData,
      };

      axios.request(options)
        .then((response) => {
          console.log(response.data);
          // this.$store.state.messageFormat = this.messageFormat;
          // this.$router.push({ name: 'result' });
        })
        .catch((error) => {
          console.error(error);
        });
    }, */
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>
<style lang="scss">
.secondary {
  background: white;

  .btn.primary {
    background: transparent;
    border: 1px solid orange;
    color: #000;
    margin-bottom: 8px;
  }
}
</style>

<style lang="scss" scoped>
section {
  text-align: left;
  padding: 15px;
  // height: 100%;

  h1 {
    margin-top: 15px;
    margin-bottom: 14px;
    text-align: left;
  }
}

body[data-daymode='true'] {
  .preview-display {
    background-color: #fff;
  }
}

body[data-daymode='false'] {
  .preview-display {
    background-color: #000;
  }
}

.preview-display {
  font-family: 'Bourton Base', sans-serif;
  width: calc(100% + 30px);
  aspect-ratio: 312 / 208;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 24px;
  word-break: break-word;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
  }

  span {
    margin: 16px 0 0 0;
    display: block;
    font-size: 12px;
  }

  > div {
    display: block;
    position: relative;
  }

  &.border-solid {
    border: 2px solid transparent;
  }

  &.border-dashed {
    outline: 2px dashed transparent;
    outline-offset: -6px;
  }

  &.border-spickel {
    border: 6px solid transparent;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid transparent;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.text-color-pink {
    h1 {
      color: #E85CA1;
    }
  }

  &.text-color-lightgreen {
    h1 {
      color: #BBDAB1;
    }
  }

  &.text-color-orange {
    h1 {
      color: #EA630D;
    }
  }

  &.text-color-darkgreen {
    h1 {
      color: #00A23C;
    }
  }

  &.text-color-blue {
    h1 {
      color: #2D489C;
    }
  }

  &.text-color-yellow {
    h1 {
      color: #FFE60B;
    }
  }

  &.text-color-magenta {
    h1 {
      color: #E20659;
    }
  }

  &.shadow-color-pink {
    h1 {
      text-shadow: 2px 2px 0 #E85CA1;
    }
  }

  &.shadow-color-lightgreen {
    h1 {
      text-shadow: 2px 2px 0 #BBDAB1;
    }
  }

  &.shadow-color-orange {
    h1 {
      text-shadow: 2px 2px 0 #EA630D;
    }
  }

  &.shadow-color-darkgreen {
    h1 {
      text-shadow: 2px 2px 0 #00A23C;
    }
  }

  &.shadow-color-blue {
    h1 {
      text-shadow: 2px 2px 0 #2D489C;
    }
  }

  &.shadow-color-yellow {
    h1 {
      text-shadow: 2px 2px 0 #FFE60B;
    }
  }

  &.shadow-color-magenta {
    h1 {
      text-shadow: 2px 2px 0 #E20659;
    }
  }

  &.border-color-pink {
    border-color: #E85CA1;
    outline-color: #E85CA1;

    &::after {
      border-top-color: inherit;
    }
  }

  &.border-color-lightgreen {
    border-color: #BBDAB1;
    outline-color: #BBDAB1;

    &::after {
      border-top-color: inherit;
    }
  }

  &.border-color-orange {
    border-color: #EA630D;
    outline-color: #EA630D;

    &::after {
      border-top-color: inherit;
    }
  }

  &.border-color-darkgreen {
    border-color: #00A23C;
    outline-color: #00A23C;

    &::after {
      border-top-color: inherit;
    }
  }

  &.border-color-blue {
    border-color: #2D489C;
    outline-color: #2D489C;

    &::after {
      border-top-color: inherit;
    }
  }

  &.border-color-yellow {
    border-color: #FFE60B;
    outline-color: #FFE60B;

    &::after {
      border-top-color: inherit;
    }
  }

  &.border-color-magenta {
    border-color: #E20659;
    outline-color: #E20659;

    &::after {
      border-top-color: inherit;
    }
  }
}

.message-controls {
  margin-bottom: 36px;
}

::-webkit-scrollbar {
  display: none;
}

.controls {
  text-align: left;

  h4 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-bottom: 7px;
  }

  ul {
    list-style-type: none;
    text-align: left;
    padding-left: 15px;
    margin: 0 -15px 15px;
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-top: 2px;
    padding-bottom: 10px;

    li {
      width: 47px;
      height: 47px;
      display: flex;
      border-radius: 100%;
      margin-right: 16px;
      position: relative;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;

      &.active {
        &:before {
          content: '';
          width: 17px;
          height: 12px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='12' viewBox='0 0 17.4 12'%3E%3Cpath id='Checkbox' d='M6.9,12,0,5.1,1.4,3.7,6.9,9.1,16,0l1.4,1.4Z' fill='%23fff'/%3E%3C/svg%3E%0A");
          display: block;
          color: white;
          position: absolute;
          font-size: 1.6rem;
          z-index: 2000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          border-radius: 100%;
        }
      }
    }
  }
}
</style>
